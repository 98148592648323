var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { LoadTranslations } from "kaiju.ui2";
import user from 'src/components/User';
import { json } from "react-router-dom";
import { redirect } from "react-router-dom";
const rootLoader = () => __awaiter(void 0, void 0, void 0, function* () { yield LoadTranslations(); yield user.setData(() => { throw json({}, { status: 401 }); }); yield user.setPromoAndUtmSettings(); yield user.loadMeta(); if (!user.permissions.some(v => ['user', 'system'].includes(v))) {
    return redirect('/login');
} return {}; });
export default rootLoader;

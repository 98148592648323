var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const confs = { method: "POST", path: '/public/rpc', headers: { 'Content-Type': 'application/json' } };
const request = (method, params) => __awaiter(void 0, void 0, void 0, function* () {
    const requestOptions = { method: confs.method, headers: confs.headers, body: JSON.stringify({ method: method, params: params }) };
    return yield fetch(confs.path, requestOptions).then(response => { const _data = response.json(); return _data; });
});
export default request;

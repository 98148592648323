import { action, makeObservable, observable } from "mobx";
import { Request } from 'kaiju.ui2';
class TableStore {
    constructor(props) {
        this.requestFunc = Request;
        this.count = 0;
        this.fields = [];
        this.header = [];
        this.data = [];
        this.fetch = false;
        this.disablePagination = false;
        this.currentPage = 1;
        this.perPage = 24;
        this.rowKey = void 0;
        this.cellID = {};
        this.cellKind = {};
        this.resizable = false;
        this.query = void 0;
        this.request = void 0;
        this.delete = void 0;
        this.rowClick = void 0;
        this.actionComponent = void 0;
        this.request = props.request;
        this.requestFunc = props.requestFunc || Request;
        makeObservable(this, { setData: action.bound, fetchData: action.bound, data: observable.shallow, currentPage: observable,
            fields: observable, header: observable.shallow });
        this.fetch = false;
        this.cellID = props.cellID || this.cellID;
        this.disablePagination = props.disablePagination || this.disablePagination;
        this.cellKind = props.cellKind || this.cellKind;
        this.rowKey = props.rowKey || 'id';
        this.actionComponent = props.actionComponent;
        this.rowClick = props.rowClick;
        this.delete = props.delete;
        this.resizable = props.resizable || false;
        this.perPage = props.perPage || this.perPage;
        this.fetchData();
    }
    setData(_ref) {
        let { data, pagination, count, header } = _ref; // this.fields = fields;
        this.data = data;
        this.count = count;
        this.header = header;
        this.currentPage = pagination['page'];
    }
    setQuery(query) { this.query = query; }
    fetchData(page) { if (!this.fetch) {
        this.fetch = true;
        const params = Object.assign(Object.assign({}, this.request.params), { query: this.query, page: page || this.currentPage, per_page: this.perPage });
        this.requestFunc(this.request.method, params).then(response => { if (response.result) {
            this.setData(response.result);
            this.fetch = false;
        } this.fetch = false; });
    } }
}
class SortableTableStore extends TableStore {
    constructor(props) { super(props); this.updateSort = void 0; this.updateSort = props.updateSort; }
    handleDragRow(sourceId, targetId) { const params = Object.assign(Object.assign({}, this.updateSort.params), { id: sourceId.id, target: targetId.id, target_sort: targetId.value }); Request(this.updateSort.method, params).then(response => { if (response.error) { }
    else {
        this.fetchData();
    } console.log("RESPONSE", response); }); }
} // const useTableStore = (props: TableInterface) => {
//     const tableStore = new TableStore(props);
//     return {tableStore}
// };
export default TableStore;
export { SortableTableStore };

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { observable, makeAutoObservable, action, computed } from "mobx";
import request from "src/utils/Request";
import { getUTMTags, saveUTMTags } from "src/utils/utmTags";
class User {
    constructor() { this.data = void 0; this.meta = void 0; this.isAuth = false; this.permissions = []; this.setData = (errorCallback) => __awaiter(this, void 0, void 0, function* () { yield request('auth.user_info').then(data => { if (data.result) {
        this.permissions = data.result.permissions;
        this.data = data.result;
        this.isAuth = true;
    }
    else if (data.error) {
        errorCallback();
    } }); }); this.setPromoAndUtmSettings = () => __awaiter(this, void 0, void 0, function* () { var _this$data, _this$data$settings; saveUTMTags(); if (!this.isAuth) {
        return;
    } const utmTags = getUTMTags(); if (!((_this$data = this.data) !== null && _this$data !== void 0 && (_this$data$settings = _this$data.settings) !== null && _this$data$settings !== void 0 && _this$data$settings.utm) && utmTags) {
        yield this.updateProfile({ 'utm': utmTags });
    } }); this.loadMeta = () => __awaiter(this, void 0, void 0, function* () { yield request('UserSettings.get').then(data => { if (data.result) {
        this.meta = data.result;
    } }); }); this.updateProfile = (settings) => __awaiter(this, void 0, void 0, function* () { if (!settings) {
        console.warn("no settings update");
        return;
    } yield request('auth.update_profile', { "settings": settings }).then(data => { if (data.result) {
        this.meta = data.result;
    } }); }); makeAutoObservable(this, { meta: observable, data: observable, setData: action.bound, loadMeta: action.bound, fullName: computed }); }
    get fullName() { var _this$data2; return (_this$data2 = this.data) === null || _this$data2 === void 0 ? void 0 : _this$data2.full_name; }
    get username() { var _this$data3; return (_this$data3 = this.data) === null || _this$data3 === void 0 ? void 0 : _this$data3.username; }
}
export default new User();

class ServerEvents {
    constructor(_ref) { let { path } = _ref; this.eventSource = void 0; this.events = {}; this.reconnectFrequencySeconds = 1; this.path = void 0; this.setupEvent = () => { this.eventSource = new EventSource(this.path); this.eventSource.onmessage = this.onmessage; this.eventSource.onopen = () => { this.reconnectFrequencySeconds = 1; }; this.eventSource.onerror = () => { this.eventSource.close(); this.reconnectFunc(); }; }; this.tryToSetupFunc = () => { console.log("tryToSetupFunc"); this.setupEvent(); this.reconnectFrequencySeconds *= 2; if (this.reconnectFrequencySeconds >= 64) {
        this.reconnectFrequencySeconds = 64;
    } }; this.reconnectFunc = () => { setTimeout(this.tryToSetupFunc, this.waitFunc()); }; this.waitFunc = () => { return this.reconnectFrequencySeconds * 1000; }; this.bindEvent = (event, func) => { this.events[event] = func; }; this.removeEvent = event => { if (Object.keys(this.events).includes(event)) {
        delete this.events[event];
    } }; this.onmessage = e => { let events = JSON.parse(e.data); events.forEach(ev => { if (Object.keys(this.events).includes(ev.event)) {
        this.events[ev.event](ev.params);
    } }); }; this.path = path; this.setupEvent(); }
}
export default new ServerEvents({ path: '/api/events' });
